<template>

  <div>

    <AreaAllocationModal
      ref="area_allocation_modal"
      @area_alloc_changed="area_alloc_changed"
    />


    <div class="card card-custom card-stretch gutter-b">

      <div class="card-header border-0 py-5">

      </div>

      <div class="card-body pt-0 table-responsive">

        <b-row>
          <b-col md="4">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="registration.family"
                    @change="top_company_registration_changed"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer"
                  >{{ $t('PAGES.REGISTRATIONSETUP.ACTIVATE_FAMILYREG') }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="registration.create_parent_members"
                    @change="top_company_registration_changed"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer"
                  >{{ $t('PAGES.REGISTRATIONSETUP.PARENT_REAL_MEMBERS') }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="registration.map_by_region"
                    @change="top_company_registration_changed"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer"
                  >{{ $t('PAGES.REGISTRATIONSETUP.SUGGEST_AREA_MEMBERSHIP') }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_EXTRA_OPTION')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_EXTRA_OPTION')}}</tip-label>

              <b-form-select
                id="field-header"
                :options="extra_options"
                v-model="registration.extra_option"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_EXTRA_VISIBILITY_OPTION')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_EXTRA_VISIBILITY_OPTION')}}</tip-label>

              <b-form-select
                id="field-header"
                :options="extra_visibility_options"
                v-model="registration.extra_visibility_option"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>


        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_LEVEL0_LABEL')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL0_LABEL')}}</tip-label>

              <b-form-input
                id="field-header"
                v-model="registration.company_select_labels[0]"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_LEVEL1_LABEL')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL1_LABEL')}}</tip-label>

              <b-form-input
                id="field-header"
                v-model="registration.company_select_labels[1]"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_LEVEL2_LABEL')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL2_LABEL')}}</tip-label>

              <b-form-input
                id="field-header"
                v-model="registration.company_select_labels[2]"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_LEVEL3_LABEL')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_LEVEL3_LABEL')}}</tip-label>

              <b-form-input
                id="field-header"
                v-model="registration.company_select_labels[3]"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-8 mb-8">
          <b-col md="4">
            <b-form-group id="group-header">
              <tip-label class="mb-2" for="field-header" :tip="$t('PAGES.REGISTRATIONSETUP.TIP_EXTRA_LABEL')" asterix>{{$t('PAGES.REGISTRATIONSETUP.HEADER_EXTRA_LABEL')}}</tip-label>

              <b-form-input
                id="field-header"
                v-model="registration.company_select_labels[4]"
                @change="top_company_registration_changed"
              />

            </b-form-group>
          </b-col>
        </b-row>


        <div v-if="loading" class="d-flex justify-content-center mb-3 mt-32">
          <b-spinner label="Loading..."></b-spinner>
        </div>

        <ExpandableTable
          v-else
          :children="company_setup_list"
          :fields="fields"
          highlightable

          @content_link_clicked="content_link_clicked"
          @cell_value_changed="company_checkbox_changed"
        />
      </div>
    </div>
  </div>


</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';
import AreaAllocationModal from '@/view/pages/ml/registrationsetup/company_select_pages/AreaAllocationModal.vue';
import TipLabel from '@/view/components/TipLabel.vue';

export default {
  name: "company-select-registration-v2",
  mixins: [ toasts ],
  props: [],
  components: {
    ExpandableTable,
    AreaAllocationModal,
    TipLabel
  },
  watch: {
    'currentCompanyId'() {
      this.get_registration_data();
    }
  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  async mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }

    const company_settings = await this.get_company_registration_setup();

    this.company_setup_list = [company_settings];

    this.get_registration_data();

    this.loading = false;
  },
  methods: {

    area_alloc_changed(company_id, area_alloc) {
      console.log('area alloc changed', company_id, area_alloc);

      this.find_and_set_value(this.company_setup_list[0], company_id, 'area_allocation', area_alloc);
    },

    async top_company_registration_changed() {
      try {

        console.log('checkbox change, updating registration', this.registration);

        this.$nextTick(async () => {
          await this.put_registration_data(this.registration);
        });
      }
      catch (err) {
        console.error(err);
      }
    },

    async company_checkbox_changed(value) {

      const company_id = value.item.company_id;

      const node = this.find_company_node(this.company_setup_list[0], company_id);

      if (node === null) {
        console.error('unable to find company in node hierarchy ' + company_id);
        return;
      }

      const v2registration = {
        membership:   node.membership,
        extra:        node.extra,
        show:         node.show,
        preselected:  node.preselected
      }

      v2registration[value.name] = value.value;

      this.find_and_set_value(this.company_setup_list[0], company_id, value.name, value.value);

      await this.put_company_registration_settings(company_id, v2registration);

    },

    find_company_node(node, company_id) {
      if (node.company_id === company_id) {
        return node;
      }

      if (node.children) {

        for (const company of node.children) {

          const found = this.find_company_node(company, company_id);

          if (found) {
            return found;
          }
        }
      }

      return null;
    },

    /// put options on the subcompany and its visibility in registration
    async put_company_registration_settings(company_id, data) {
      try {
        const res = await axios.put(`/company/settings/v2registration/${company_id}`, data);

        if (res.status !== 200) {
          console.error('unabele to update settings');
          return;
        }


      }
      catch (err) {
        console.error(err);
      }
    },

    async get_company_registration_setup() {
      try {
        const res = await axios.get(`/company/registration/hierarchy/${this.currentCompanyId}`);

        if (res.status !== 200) {
          return null;
        }

        return res.data;
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    content_link_clicked(name, item, row_index, col_index) {

      this.$refs['area_allocation_modal'].show();
      this.$refs['area_allocation_modal'].load_company(item.company_id);

    },

    find_and_set_value(node, company_id, field_name, value) {

      if (node.company_id === company_id) {
        node[field_name] = value;
        // Vue3 compatability for Vue.set
        node = { ...node };
        return true;
      }

      if (node.children) {

        for (const company of node.children) {

          const found = this.find_and_set_value(company, company_id, field_name, value);

          if (found) {
            return true;
          }
        }
      }


      return false;
    },

    /// save the registration data of the top company
    async put_registration_data(registration) {
      try {

        console.log('---- putting registration:', registration);
        const res = await axios.put(`/company/${this.currentCompanyId}`, {
          registration: registration
        });

        if (res.status !== 200) {
          console.error('unable to update registration');
          return;
        }

      }
      catch (err) {
        console.error(err);
      }
    },

    async get_registration_data() {
      try {

        const res = await axios.get(`/company/${this.currentCompanyId}`);

        if (res.status !== 200) {
          console.error('unable to update registration');
          return;
        }

        this.registration = res.data.registration;

        if (!this.registration.company_select_labels || this.registration.company_select_labels.length !== 5) {
          this.registration.company_select_labels = ['','','','',''];
          // Vue3 compatability for Vue.set
          this.registration = { ...this.registration };
        }

      }
      catch (err) {
        console.error(err);
      }
    }


  },
  data() {
    return {
      registration: {
        lang: 'sv',
        is_region: false,
        if_pcomp_reg_allow_reg: false,
        map_by_region: false,
        allow_multiple: false,
        create_parent_members: false,
        demand_parents: false,
        show_from_pc: false,
        company_select_labels: ['','','','',''],
        join_cond: [
          { type: 'max', val: '60' },
          { type: 'min', val: '18' }
        ],
        pages: [],
        age_req_all: [18, 20],
        family: false,
        age_max_parent: 0,
      },
      extra_option: null,
      extra_options: [
        { text: this.$t('PAGES.REGISTRATIONSETUP.EXTRA_CHECKBOX'), value: 'checkbox' },
        { text: this.$t('PAGES.REGISTRATIONSETUP.EXTRA_RADIO'), value: 'radio' },
        { text: this.$t('PAGES.REGISTRATIONSETUP.EXTRA_NONE'), value: null },

      ],
      extra_visibility_options: [
        { text: this.$t('PAGES.REGISTRATIONSETUP.EXTRA_VISIBILITY_SEARCH'), value: 'search' },
        { text: this.$t('PAGES.REGISTRATIONSETUP.EXTRA_VISIBILITY_LIST'), value: 'list' },
      ],

      loading: true,
      fields: [
        { name: 'name', text: this.$t('PAGES.REGISTRATIONSETUP.COMPANY_NAME'), cols: 2 },
        { name: 'show', text: this.$t('PAGES.REGISTRATIONSETUP.SHOW'), cols: 2, type: 'checkbox' },
        { name: 'membership', text: this.$t('PAGES.REGISTRATIONSETUP.MEMBERSHIP'), cols: 2, type: 'checkbox' },
        { name: 'preselected', text: this.$t('PAGES.REGISTRATIONSETUP.PRESELECTED'), cols: 2, type: 'checkbox' },
        { name: 'extra', text: this.$t('PAGES.REGISTRATIONSETUP.EXTRA'), cols: 1, type: 'checkbox' },
        { name: 'area_allocation', text: this.$t('PAGES.REGISTRATIONSETUP.AREA_ALLOCATION'), cols: 2, type: 'link',
          __format(row, col, item) {
            if (row === 0) {
              return '';
            }

            return item === null || item === undefined || item.length === 0 ? 'Not assigned' : 'Assigned';
          }
        },
      ],

      company_setup_list: [
        {
          name: 'Riksförbundet Svea',
          company_id: 'a',
          membership: false,
          preselected: false,
          extra: true,
          show: false,
          area_allocation: 'Ej tilldelat',
          children: [
            {
              name: 'Uppland',
              company_id: 'z67bghm9',
              membership: false,
              preselected: false,
              extra: false,
              show: false,
              area_allocation: 'Tilldelat',
              children: [
                {
                  name: 'Uppsala',
                  company_id: 'c',
                  membership: false,
                  preselected: false,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                },
                {
                  name: 'Enköping',
                  company_id: 'd',
                  membership: false,
                  preselected: true,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                }

              ]
            },
            {
              name: 'Småland',
              company_id: 'e',
              membership: false,
              preselected: false,
              extra: false,
              show: false,
              area_allocation: 'Ej tilldelat',
              children: [
                {
                  name: 'Kalmar',
                  company_id: 'f',
                  membership: false,
                  preselected: true,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                },
                {
                  name: 'Växjö',
                  company_id: 'g',
                  membership: false,
                  preselected: false,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                }

              ]
            },
            {
              name: 'Stockholm',
              company_id: 'h',
              membership: false,
              preselected: false,
              extra: false,
              show: false,
              area_allocation: 'Ej tilldelat',
              children: [
                {
                  name: 'Stor-Stockholm',
                  company_id: 'i',
                  membership: false,
                  preselected: false,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                },
                {
                  name: 'Kungsholmen',
                  company_id: 'j',
                  membership: false,
                  preselected: false,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                },
                {
                  name: 'Hässelby',
                  company_id: 'k',
                  membership: false,
                  preselected: false,
                  extra: false,
                  show: false,
                  area_allocation: 'Ej tilldelat',
                }
              ]
            }
          ]
        }
      ]
    }
  }
};
</script>
